import { Box, Typography } from "@mui/material";
import { useTranslation, withTranslation } from "next-i18next";
import { GetServerSidePropsContext } from "next";
import { getLanguageFromRequest } from "../utils";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";

export default function ErrorPage({ statusCode }: any) {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      { statusCode === 404 ? (
        <Typography variant="h5">{t("404.title")}</Typography>
      ) : (
        <Typography variant="h5">{t("error.title")}</Typography>
      )}
    </Box>
  );
}

export async function getServerSideProps({
  res,
  req,
}: GetServerSidePropsContext) {
  const statusCode = res ? res.statusCode : 404;
  const locale = getLanguageFromRequest(req);

  return {
    props: {
      ...(await serverSideTranslations(locale, ["common"])),
      statusCode,
    },
  };
}
